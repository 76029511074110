import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FileUploadTaskService } from 'src/app/services/file-upload-task/file-upload-task.service';
import { FileUploadTaskDto, SortOrder, SortingElement, TasksStatus } from 'src/app/web-api-client';
import { DatePipe, Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { CommonService } from 'src/app/services/common/common.service';
import { OutstandingInput } from 'src/app/models/outstandingInput';
import { FilterParameter } from 'src/app/models/FilterPrameter';
import { ApprovalQuestion } from 'src/app/models/approvalQuestion';


@Component({
  selector: 'app-file-upload-task-list',
  templateUrl: './file-upload-task-list.component.html',
  styleUrls: ['./file-upload-task-list.component.scss']
})
export class FileUploadTaskListComponent implements OnInit {

  // loading indicator
  loadingUploadTask: boolean = false;

  // take the process as input
  @Input() process: string;

  // take the title as input
  @Input() title: string;

  // input for pagination
  @Input() page: number = 1;
  @Input() pageSize: number = 30;
  @Input() onOverview: boolean = false;
  // input for back button
  @Input() backBtn: boolean = true;
  @Input() displayFilter: boolean = false;

  @Input() networkMemberId: string = "";
  @Input() noResultText: string = "There are no tasks to display";
  @Input() statusFilter: number = null;
  @Input() tableView: boolean;
  @Input() headers: { head: string, property: string }[];
  // hold the upload file tasks
  @Input() isOnClientView: boolean = false;
  @Input() sortingElements: SortingElement[] = [];

  dateTime: Date = new Date(Date.now());
  items: FileUploadTaskDto[] = [];
  isNetworkMember: boolean;
  hideShowMoreBtn: boolean = false;
  clientIdParam: string;
  params: ParamMap;
  lastRefreshDate: string = ""
  outstandingInputs: OutstandingInput[] = [];
  nmId: string = ""
  // constructor
  constructor(
    private activatedroute: ActivatedRoute,
    private fileUploadTaskService: FileUploadTaskService,
    public location: Location,
    private userService: UserService,
    public commonService: CommonService,
    public datePipe: DatePipe) { }

  // oninit method
  async ngOnInit() {
    this.lastRefreshDate = await this.commonService.getHangfireJobStartDateExecution(this.process);
    // reset the active task
    this.fileUploadTaskService.activeFileUploadTask = null;
    this.clientIdParam = this.params?.get('clientId');


    await this.checkUserRoles();

    if (this.isNetworkMember) {
      this.nmId = (await this.userService.getCurrentUser()).networkMemberId
    }
  }

  async checkUserRoles() {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember)
    this.activatedroute.queryParamMap.subscribe(async (params) => await this.loadtasks(params));
  }

  async loadMore() {
    if (!this.loadingUploadTask) {
      this.page++
      await this.loadtasks(null, true);
    }
  }

  async loadtasks(params?: any, loadMore?: boolean) {
    if (params) {
      this.params = params;
    }
    else {
      params = this.params;
    }

    if (!loadMore) {
      this.items = [];
      this.page = 1
    }

    // loading started
    this.loadingUploadTask = true;
    let newItems: FileUploadTaskDto[] = [];

    if (this.process) {
      var nmId : string = (params?.get("networkMemberId") ?? localStorage.getItem('networkMemberId'));
      var clientId = (params?.get("clientId") ?? localStorage.getItem('clientId'));
      var clientName = (params?.get("clientName") ?? localStorage.getItem('clientName'));
      var subscriberId = params?.get('subscriberId');

      var storageStatus = localStorage.getItem('status');
      var status: TasksStatus = storageStatus == "null" ? null : parseInt(storageStatus);

      var storageDueDate = localStorage.getItem('dueDate');
      var filterParameter: FilterParameter = {} as FilterParameter;
      filterParameter.NetworkMemberIds = nmId == null ? null : [nmId];
      filterParameter.ClientId = clientId;
      filterParameter.ClientName = clientName;
      filterParameter.SubscriberId = subscriberId;
      filterParameter.Status = status;
      filterParameter.ProcessId = this.process;
      filterParameter.OverdueDate = parseInt(storageDueDate)
      filterParameter.NetworkMemberId = nmId

      //Sort by status
      var sortBystatus = new SortingElement();
      sortBystatus.columnName = this.getPropertyName((obj: FileUploadTaskDto) => obj.status);
      sortBystatus.sortOrder = SortOrder.Ascending;
      this.sortingElements.push(sortBystatus);

      if (this.process == "annual-report") {
        //Sort by created Date
        var sortBycreatedDate = new SortingElement();
        sortBycreatedDate.columnName = "MetaData.ApprovalQuestions.SendNMDate"
        sortBycreatedDate.sortOrder = SortOrder.Descending
        this.sortingElements.push(sortBycreatedDate);
      }
      else {
        //Sort by Client name
        var sortByClient = new SortingElement();
        sortByClient.columnName = this.getPropertyName((obj: FileUploadTaskDto) => obj.clientId);
        sortByClient.sortOrder = SortOrder.Ascending
        this.sortingElements.push(sortByClient);
      }

      if (this.isNetworkMember) {
        newItems = await this.fileUploadTaskService.getMyOrganization(filterParameter, this.sortingElements, this.page, this.pageSize);
      } else {

        if (nmId == null) {
          filterParameter.NetworkMemberIds = [];
        }

        newItems = await this.fileUploadTaskService.getTasks(filterParameter, this.sortingElements, this.page, this.pageSize);
      }

      if (newItems.length < this.pageSize) {
        this.hideShowMoreBtn = true;
      } else {
        this.hideShowMoreBtn = false;
      }
      var concat = this.items.concat(newItems);
      this.items = concat;
    }
    this.sortingElements = []
    // loading finished
    this.loadingUploadTask = false;
  }

  getSendNmDate(metadata: string) {
    const parsed = JSON.parse(metadata);

    var approvalQuestions: ApprovalQuestion[] = parsed.ApprovalQuestions;
    return this.datePipe.transform(approvalQuestions[0].SendNMDate)
  }

  getSendClientDate(metaData: string) {
    const parsed = JSON.parse(metaData);
    var approvalQuestions: ApprovalQuestion[] = parsed.ApprovalQuestions;
    return this.datePipe.transform(approvalQuestions[0].SendClientDate)
  }

  getPropertyName<T>(propertyAccessor: (obj: T) => any): string {
    const match = propertyAccessor.toString().match(/\.([a-zA-Z0-9_]+)/);
    return match ? match[1] : '';
  }
}
