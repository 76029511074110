import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { CommonService } from 'src/app/services/common/common.service';
import { NetworkMembersService } from 'src/app/services/network-members/network-members.service';
import { UserService } from 'src/app/services/user.service';
import { AccountDto } from 'src/app/web-api-client';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})

export class OverviewComponent implements OnInit {

  isFinancial: boolean = false;
  isNetworkMember: boolean = true;
  isCollaborator: boolean = false;
  @Input() searchNetworkMember: boolean = true;
  @Input() title: string;
  @Input() hideServiceFees: boolean = false;
  @Input() client: AccountDto;
  @Input() isOnClientView: boolean = false;
  @Input() clientId: string;

  @Input() networkMemberId: string;
  networkMemberName: string = "";

  constructor(private userService: UserService,
    private router: Router,
    public networkMembersService: NetworkMembersService,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private titleService: Title) { }

  async ngOnInit() {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    this.isFinancial = await this.userService.isFinancial();

    
    this.activatedRoute.queryParamMap.subscribe(async (params) => {

      if (params.has('networkMemberId')) {
        this.networkMemberId = params.get('networkMemberId');
      }
      else {
        this.networkMemberId = localStorage.getItem('networkMemberId');
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams: { networkMemberId: this.networkMemberId },
            queryParamsHandling: 'merge',
          });
      }

      this.networkMemberName = "";
      if (this.networkMemberId) {
        var account = (await this.networkMembersService.getNetworkMemberById(this.networkMemberId))
        this.networkMemberName = account.accountName
      }

    });

    // set the title
    this.titleService.setTitle("Overview | Insurope");

  }
}
