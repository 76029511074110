import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommentService } from 'src/app/services/comment.service';
import { UserService } from 'src/app/services/user.service';
import { DiscussionDto, TaskSubscribersDto, Comment, TasksStatus, CommentDto } from 'src/app/web-api-client';
import { faTrashCan, faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import jsPDF from 'jspdf';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TagReplyParameter } from 'src/app/models/TagReplyParameter';
import { CommentFormDto } from 'src/app/models/CommentFormDto';
import { AddCommentDto } from 'src/app/models/AddCommentDto';
import { ProcessIds } from 'src/app/models/enums/processIds';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { EditCommentDto } from 'src/app/models/EditCommentDto';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})

export class CommentListComponent implements OnInit {
  @Input() taskId: string = this.activatedroute.snapshot.paramMap.get("id");
  @Input() status: TasksStatus;
  @Input() customSubscriberProvider: string = "";
  @Input() networkMemberId: string;
  @Input() networkMemberName: string;
  @Input() clientName: string;
  @Input() clientNameList: string[];
  @Input() country: string;
  @Input() periodEnd: string;
  @Input() customProcessId: string = "";
  @Input() illustrationRequestSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @Input() service: any;
  @Input() isTagSystemApplicable: boolean = true;

  @Output() commentIdReply = new EventEmitter<string[]>();

  replyTagsSubject: Subject<TagReplyParameter[]> = new Subject<TagReplyParameter[]>();
  tagSelected: Subject<string> = new Subject<string>();
  quillEditorSetFocus: Subject<void> = new Subject();

  lastExportedDate: Date;
  userId: string;
  dateTime = new Date()
  discussion: DiscussionDto;
  comments: Comment[] = [];
  commentId: number = 0;
  faTrashCan = faTrashCan;
  faCircleXmark = faCircleXmark;
  isNetworkMember: boolean;
  isClient: boolean;
  IsCollaborator: boolean;
  searchResult: [];
  replyTags: string[] = [];
  selectedTag: string = "";
  processId: string;
  filteredComments: Comment[] = [];
  isAllToggleShowMoreComplete: boolean = false;
  loading: boolean = false;
  commentsNodes: Node[] = [];
  replyTagsSubscription: Subscription;
  commentSubscribersSubscription: Subscription;

  taskSubscribers: TaskSubscribersDto = {
    clientSubscribers: [],
    networkMemberSubscribers: [],
    insuropeSubscribers: []
  } as TaskSubscribersDto;

  @ViewChild('discussionTable') discussionTable!: ElementRef;
  @ViewChild('searchTagField', { static: true }) searchTagField: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(private commentService: CommentService, private userService: UserService, private activatedroute: ActivatedRoute) { }

  ngOnInit() {
    this.processId = this.commentService.task.processId;
    if (this.customProcessId != "" && (this.processId == "" || this.processId == null)) {
      this.processId = this.customProcessId;
    }

    this.comments = this.commentService.comments;
    this.filteredComments = this.comments;
    this.discussion = this.commentService.discussion;

    this.commentSubscribersSubscription = this.commentService.updateCommentSubscribers.subscribe((subscribers) => {
      this.taskSubscribers = subscribers;
    });

    this.getSubscibers()
  }

  // Load subscribers from the service
  async getSubscibers() {
    try {
      if (this.customSubscriberProvider.trim() !== "") {
        this.taskSubscribers = await this.commentService.getIllustrationTaskSubscriber(this.taskId,
          this.illustrationRequestSubscribers.clientSubscribers,
          this.illustrationRequestSubscribers.insuropeSubscribers,
          this.illustrationRequestSubscribers.networkMemberSubscribers
        );
      }
      else {
        this.taskSubscribers = this.commentService.taskSubscribers;
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  // Add a comment at the top of the comments array
  async addComment(response: CommentFormDto) {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isClient = await this.userService.isUserInRole(UserRoles.Client);
    this.IsCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);
    var task = this.commentService.task;

    if (response.isEdit) {
      let editCommentDto: EditCommentDto = {
        TaskId: this.taskId,
        CommentId: response.commentId,
        Content: response.content,
        SendNotification: response.sentNotification,
        PeriodEnd: this.periodEnd,
        ProcessName: this.processId,
        ClientName: this.clientName,
        NetworkMemberName: task.networkMemberName,
        CustomSubscriberProvider: task.customSubscriberProvider,
        Country: this.country,
        Title: task.title,
      };

      await this.commentService.editComment(editCommentDto);
    } else {
      let addCommentDto: AddCommentDto = {
        taskId: this.taskId,
        content: response.content,
        tags: response.commentTags,
        customSubscriberProvider: this.customSubscriberProvider,
        processName: this.processId,
        clientName: this.clientName,
        networkMemberName: this.networkMemberName,
        files: response.attachments,
        country: this.country,
        periodEnd: this.periodEnd,
        commentType: response.commentType,
        isDeleted: false,
        isEdited: false,
        isHtmlText: true,
        notificationFrequency: response.notificationFrequency,
        notificationType: response.notificationType
      };

      var comment = await this.commentService.addComment(addCommentDto).then();

      if (comment != undefined && !(this.processId == ProcessIds.accountInputs || this.processId == ProcessIds.annualReport || this.processId == ProcessIds.illustrations)) {
        if ((this.isNetworkMember || this.isClient) && this.status == TasksStatus.Open) {
          await this.service.changeTaskStatus(this.taskId, TasksStatus.InProgress);
        }

        if (this.IsCollaborator && this.status == TasksStatus.InProgress) {
          await this.service.changeTaskStatus(this.taskId, TasksStatus.Open);
        }
      }
    }
  }

  // export the discussion

  async exportDiscussion() {
    this.loading = true;
    const exportCommentBuilder = [];
    const imageData: { src: string; x: number; y: number; width: number; height: number }[] = [];

    for (const comment of this.comments) {
      if (comment.content?.trim().length < 1) continue;

      const messageHeader = `<blue>${comment.firstName} ${comment.lastName} (${comment.messageSent.toDateString()}):</blue>`;
      //console.log(comment.content);
      const { text, images } = await this.htmlToPlainTextWithImages(comment.content);
      exportCommentBuilder.push(messageHeader + text);
      imageData.push(...images);
    }

    await this.generatePDF(
      exportCommentBuilder.join("\n\n"),
      imageData,
      `${this.commentService.task.title} - ${this.commentService.task.networkMember?.accountName}.pdf`
    );

    this.loading = false;
  }

  async htmlToPlainTextWithImages(html: string): Promise<{ text: string; images: { src: string; x: number; y: number; width: number; height: number }[] }> {
    const div = document.createElement('div');
    div.innerHTML = html;

    const images: { src: string; x: number; y: number; width: number; height: number }[] = [];
    const text = await this.traverseDOMWithImages(div, images);

    return { text, images };
  }

  async traverseDOMWithImages(node: Node, images: { src: string; x: number; y: number; width: number; height: number }[], depth = 0, maxDepth = 100): Promise<string> {
    if (depth > maxDepth) {
      console.warn('Maximum depth reached. Stopping traversal to prevent infinite recursion.');
      return '';
    }

    let text = '';

    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent || '';
    } else if (node.nodeType === Node.ELEMENT_NODE && node instanceof Element) {
      if (node.tagName === 'BR') {
        text += '\n';
      } else if (node.tagName === 'P') {
        text += '\n' + (await this.traverseChildren(node, images, depth, maxDepth)) + '\n';
      } else if (node.tagName === 'LI') {
        text += '\n- ' + node.textContent.trim();
      } else if (node.tagName === 'IMG') {
        const imgSrc = node.getAttribute('src');
        if (imgSrc) {
          const img = new Image();
          img.src = imgSrc;

          await new Promise((resolve) => (img.onload = resolve));

          let imgWidth = img.naturalWidth;
          let imgHeight = img.naturalHeight;

          const maxWidth = 500; // Adjust as needed
          const maxHeight = 500; // Adjust as needed

          if (imgWidth > maxWidth || imgHeight > maxHeight) {
            const widthRatio = maxWidth / imgWidth;
            const heightRatio = maxHeight / imgHeight;
            const scale = Math.min(widthRatio, heightRatio);

            imgWidth *= scale;
            imgHeight *= scale;
          }

          images.push({ src: imgSrc, x: 0, y: 0, width: imgWidth, height: imgHeight });
          text += `\n[IMAGE]\n`; // Placeholder for the image
        }
      } else if (node.tagName === 'BLUE') {
        text += `<blue>${node.textContent}</blue>`;
      } else {
        text += await this.traverseChildren(node, images, depth, maxDepth);
      }
    }
    return text;
  }

  async traverseChildren(node: Node, images: { src: string; x: number; y: number; width: number; height: number }[], depth: number, maxDepth: number): Promise<string> {
    let text = '';

    for (const child of Array.from(node.childNodes)) {
      const childText = await this.traverseDOMWithImages(child, images, depth + 1, maxDepth);
      text += childText;
    }

    return text;
  }


  async generatePDF(
    text: string,
    images: { src: string; x: number; y: number; width?: number; height?: number }[],
    fileName: string = 'Document.pdf'
  ) {
    const doc = new jsPDF();
    const margin = 10;
    const lineSpacing = 7;
    const pageWidth = doc.internal.pageSize.width - margin * 2;
    const pageHeight = doc.internal.pageSize.height - margin * 2;

    let y = margin;
    let x = margin;

    const lines = text.split('\n');

    for (const line of lines) {
      if (line.startsWith("<blue>") && line.endsWith("</blue>")) {
        doc.setTextColor(55, 138, 192); // Blue text color
        const cleanLine = line.replace("<blue>", "").replace("</blue>", "");
        doc.text(cleanLine, x, y);
      } else if (line === "[IMAGE]") {
        // Handle image placement
        if (images.length > 0) {
          const image = images.shift();
          if (image) {
            const imgData = await this.convertImageToBase64(image.src);

            // Get original image dimensions
            const originalImg = new Image();
            originalImg.src = image.src;
            await new Promise((resolve) => (originalImg.onload = resolve));

            let imgWidth = originalImg.width;
            let imgHeight = originalImg.height;

            // Only resize if the image is larger than the PDF page
            if (imgWidth > pageWidth || imgHeight > pageHeight) {
              const widthRatio = pageWidth / imgWidth;
              const heightRatio = pageHeight / imgHeight;
              const scale = Math.min(widthRatio, heightRatio);

              imgWidth *= scale;
              imgHeight *= scale;
            }

            // Add image to the PDF without enlarging small images
            if (y + imgHeight > doc.internal.pageSize.height - margin) {
              // Add a new page if the image doesn't fit
              doc.addPage();
              y = margin;
            }

            doc.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
            y += imgHeight + lineSpacing; // Adjust Y position after the image
          }
        }
      } else {
        doc.setTextColor(0, 0, 0); // Default black text color
        doc.text(line, x, y);
      }

      y += lineSpacing;

      if (y > doc.internal.pageSize.height - margin) {
        doc.addPage();
        y = margin;
      }
    }

    doc.save(fileName);
  }


  // traverseDOMWithImages(
  //   node: Node,
  //   images: { src: string; x: number; y: number; width: number; height: number }[],
  //   depth = 0,
  //   maxDepth = 100
  // ): string {
  //   if (depth > maxDepth) {
  //     console.warn('Maximum depth reached. Stopping traversal to prevent infinite recursion.');
  //     return '';
  //   }

  //   let text = '';

  //   if (node.nodeType === Node.TEXT_NODE) {
  //     text += node.textContent;
  //   } else if (node.nodeType === Node.ELEMENT_NODE && node instanceof Element) {
  //     if (node.tagName === 'BR') {
  //       text += '\n';
  //     } else if (node.tagName === 'P') {
  //       // Add a line break before and after the content of <p>
  //       text += '\n' + this.traverseChildren(node, images, depth, maxDepth) + '\n';
  //     } else if (node.tagName === 'LI') {
  //       text += '\n- ' + node.textContent.trim();
  //     } else if (node.tagName === 'IMG') {
  //       const imgSrc = node.getAttribute('src');
  //       const width = 175; // Default width (adjust as needed)
  //       const height = 100; // Default height (adjust as needed)
  //       if (imgSrc) {
  //         images.push({ src: imgSrc, x: 0, y: 0, width, height });
  //         text += `\n[IMAGE]\n`; // Placeholder for the image
  //       }
  //     } else if (node.tagName === 'BLUE') {
  //       text += `<blue>${node.textContent}</blue>`;
  //     } else {
  //       text += this.traverseChildren(node, images, depth, maxDepth);
  //     }
  //   }

  //   return text;
  // }

  async convertImageToBase64(url: string): Promise<string> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  copySubscriberEmail(val: string) {
    navigator.clipboard.writeText(val);
  }

  getNetworkMemberId() {
    return this.networkMemberId;
  }

  async addSubscriber(event: { id: string; }) {
    var sub = await this.commentService.addSubscriber(this.taskId, event.id, this.processId);
    this.taskSubscribers = sub;
  }

  async deleteSubscriber(taskSubscribers: TaskSubscribersDto) {
    var subs = await this.commentService.deleteSubscriber(this.taskId, taskSubscribers.id);
    this.taskSubscribers = subs;
  }

  isCommentDeletable(comment: Comment) {
    var limitDate = comment.messageSent.getDate() + 1;
    var deleteComment = true;

    if (comment.userId == this.userId) {
      deleteComment = false;
      if (comment.attachments?.length > 0 && limitDate < this.dateTime.getDate()) {
        deleteComment = true;
      }
    }
    return deleteComment;
  }

  async loadTagByName(tagName: string) {
    if (tagName == '') {
      this.filteredComments = this.comments;
    } else {
      this.filteredComments = this.comments.filter(comment => comment.tags.some((e) => e.tagName.toLowerCase().startsWith(tagName.toLowerCase())))
      this.searchResult = [];
      this.selectedTag = tagName;
    }
  }

  async clearSearch() {
    this.searchResult = [];
    this.filteredComments = this.comments;
    this.searchTagField.nativeElement.value = "";
  }

  updateCommentList(comments: CommentDto[]) {
    this.filteredComments = comments;
  }

  updateSelectedTag(tag: string) {
    this.tagSelected.next(tag);
  }

  setQuillFocusEvent() {
    this.quillEditorSetFocus.next();
  }

  onNgDestroy() {
    this.replyTagsSubscription.unsubscribe();
    this.commentSubscribersSubscription.unsubscribe();
  }
}
