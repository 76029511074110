import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { UserRoles } from "src/app/models/enums/user-roles";
import { UserService } from "src/app/services/user.service";
import { GeneralTaskDto, PayToEnum, SortOrder, SortingElement, TasksStatus } from "src/app/web-api-client";
import { InformativeService } from "../../../../services/informative.service";
import { Location } from "@angular/common";
import { CommonService } from "src/app/services/common/common.service";
import { FilterParameter } from "src/app/models/FilterPrameter";
import { parseDate } from "ngx-bootstrap/chronos";

@Component({
  selector: "app-informative-list",
  templateUrl: "./informative-list.component.html",
  styleUrls: ["./informative-list.component.scss"],
})
export class InformativeListComponent implements OnInit {

  // loading indicator
  loadingInformativeTask: boolean = false;

  @Input() process: string;
  @Input() title: string;
  @Input() onOverview: boolean = false;
  // input for pagination
  @Input() page: number = 1;
  @Input() pageSize: number = 30;
  items: GeneralTaskDto[] = [];
  @Input() displayFilter: boolean = false;

  @Input() statusFilter: number = null;
  @Input() displaySubTitle: boolean = true;
  isNetworkMember: boolean;
  hideShowMoreBtn: boolean = false;
  @Input() tableView: boolean = false;
  @Input() headers: { head: string, property: string }[];
  clientIdParam: string;
  @Input() searchDateLimit: Date;
  @Input() isOnClientView: boolean = false;
  @Input() networkMemberId: string = "";
  @Input() sortingElements: SortingElement[] = [];

  currentDate: Date = new Date(Date.now());
  dateTime: Date = new Date(Date.now());
  params: ParamMap;
  lastRefreshDate: string = "";
  isMuView: boolean = false;
  processId: string;

  constructor(
    private informativeService: InformativeService,
    private activatedroute: ActivatedRoute,
    public location: Location,
    private userService: UserService,
    public commonService: CommonService
  ) {
    var defaultDate = new Date(Date.now());
    this.searchDateLimit = new Date(defaultDate.setFullYear(defaultDate.getFullYear() - 3));
    var isMuView = JSON.parse(localStorage.getItem("isPaymentMultipoolView"));

    if (isMuView == null) {
      isMuView = false;
    }

    this.updateProcess(isMuView);
    this.processId = this.process;
  }

  async ngOnInit() {
    if (this.process == "payments" || this.process == "payments-mu") {
      if (this.processId && this.processId != this.process) {
        this.process = this.processId;
      }
    }

    await this.checkUserRoles();
    this.lastRefreshDate = await this.commonService.getHangfireJobStartDateExecution(this.process);
  }

  async checkUserRoles() {
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    if (this.isNetworkMember && this.headers) {
      this.headers = this.headers?.filter(x => x.head != "Network Member");
    }
    this.activatedroute.queryParamMap.subscribe(async (params) => await this.loadInformatives(params));
  }

  async loadMore() {
    if (!this.loadingInformativeTask) {
      this.page++
      await this.loadInformatives(null, true);
    }
  }

  async loadInformatives(params?: any, loadMore?: boolean) {

    if (params) {
      this.params = params;
    }
    else {
      params = this.params;
    }

    if (!loadMore) {
      this.items = [];
      this.page = 1
    }

    let newItems;

    // loading started
    this.loadingInformativeTask = true;

    if (this.process) {
      var nmId = (params?.get("networkMemberId") ?? localStorage.getItem('networkMemberId'));
      var clientId = (params?.get("clientId") ?? localStorage.getItem('clientId'));
      var clientName = (params?.get("clientName"))
      var subscriberId = params?.get('subscriberId');

      var storageStatus = localStorage.getItem('status');

      var status: TasksStatus = storageStatus == "null" ? null : parseInt(storageStatus);

      var storageDueDate = localStorage.getItem('dueDate');
      var DueDate: Date = storageDueDate == null ? null : parseDate(storageDueDate + "-01-01");

      var storagePayTo = localStorage.getItem('payTo');
      var payTo: PayToEnum = storagePayTo == null ? null : parseInt(storagePayTo);


      var filterParameter: FilterParameter = {} as FilterParameter;
      filterParameter.NetworkMemberIds = nmId == null ? null : [nmId];
      filterParameter.ClientId = clientId;
      filterParameter.ClientName = clientName;
      filterParameter.SubscriberId = subscriberId;
      filterParameter.ProcessId = this.process;
      filterParameter.Status = status;
      filterParameter.PayTo = payTo;
      filterParameter.OverdueDate = parseInt(storageDueDate)
      filterParameter.NetworkMemberId = nmId
      //Sort by status
      var sortBystatus = new SortingElement();
      sortBystatus.columnName = this.getPropertyName((obj: GeneralTaskDto) => obj.status);
      sortBystatus.sortOrder = SortOrder.Ascending
      this.sortingElements.push(sortBystatus);

      //Sort by DueDate
      var sortByDueDate = new SortingElement();
      sortByDueDate.columnName = this.getPropertyName((obj: GeneralTaskDto) => obj.overdueDate);
      sortByDueDate.sortOrder = SortOrder.Descending
      this.sortingElements.push(sortByDueDate);

      if (this.isNetworkMember) {
        newItems = await this.informativeService.getMyOrganization(filterParameter, this.sortingElements, this.page, this.pageSize);
      } else {
        if (nmId == null) {
          filterParameter.NetworkMemberIds = [];
        }

        newItems = await this.informativeService.getTasks(filterParameter, this.sortingElements, this.page, this.pageSize);
      }

      newItems.forEach((item: { displayDate: Date; }) => {
        var newDueDate = new Date(item.displayDate?.getFullYear(), item.displayDate?.getMonth(), item.displayDate?.getDate() + 30);
        item.displayDate = newDueDate;
      });

      if (newItems.length < this.pageSize) {
        this.hideShowMoreBtn = true;
      } else {
        this.hideShowMoreBtn = false;
      }

      var concat = this.items.concat(newItems);
      this.items = concat;
    }
    this.sortingElements = []
    // loading finished
    this.loadingInformativeTask = false;
  }

  changeIsMuView(muView: boolean) {
    this.updateProcess(muView);
    this.updateTitle(muView);

    this.processId = this.process;
    this.items = [];

    this.ngOnInit()
  }

  updateProcess(muView: boolean) {
    this.isMuView = muView
    if (muView) {
      this.process = "payments-mu"
    } else {
      this.process = "payments"
    }

    localStorage.setItem("isPaymentMultipoolView", JSON.stringify(muView));
  }

  updateTitle(muView: boolean) {
    if (muView) {
      this.title = "Multipool Payments"
    } else {
      this.title = "Payments"
    }
  }

  getPropertyName<T>(propertyAccessor: (obj: T) => any): string {
    const match = propertyAccessor.toString().match(/\.([a-zA-Z0-9_]+)/);
    return match ? match[1] : '';
  }
}
